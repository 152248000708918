import React, { useState } from "react"
import gsap from "gsap"
import CSSRulePlugin from "gsap/CSSRulePlugin"

// gsap.registerPlugin(CSSPlugin)

// Components
import StyledCard from "../../Global/StyledCard/StyledCard"

// Styles
import styles from "./expertise.module.sass"

// Icons
import NodeJsIcon from "../../../icons/technologies/icons/nodejs.svg"
import PythonIcon from "../../../icons/technologies/icons/python.svg"
import GoLangIcon from "../../../icons/technologies/icons/golang.svg"
import TypeScriptIcon from "../../../icons/technologies/icons/typescript.svg"
import SolidityIcon from "../../../icons/technologies/icons/solidity.svg"
import LigoIcon from "../../../icons/technologies/icons/ligo.svg"
import ReactIcon from "../../../icons/technologies/icons/react_icon.svg"
import VueJSIcon from "../../../icons/technologies/icons/vuejs.svg"
import SassIcon from "../../../icons/technologies/icons/sass.svg"
import EthereumIcon from "../../../icons/technologies/icons/ethereu.svg"
import TronIcon from "../../../icons/technologies/icons/tron.svg"
import TezosIcon from "../../../icons/technologies/icons/tezos.svg"
import EosIcon from "../../../icons/technologies/icons/eos.svg"

import ShieldShape from "../../../icons/technologies/shapes/shield.svg"
import ShieldShapeHover from "../../../icons/technologies/shapes/shield-hover.svg"
import BlockShape from "../../../icons/technologies/shapes/block.svg"
import BlockShapeHover from "../../../icons/technologies/shapes/block-hover.svg"
import CircleShape from "../../../icons/technologies/shapes/circle.svg"
import CircleShapeHover from "../../../icons/technologies/shapes/circle-hover.svg"
import SquareShape from "../../../icons/technologies/shapes/square.svg"
import SquareShapeHover from "../../../icons/technologies/shapes/square-hover.svg"

import JavaScriptBig from "../../../icons/services/JavaScriptBig.svg"
import JavaScriptBigActive from "../../../icons/services/JavaScriptBigActive.svg"
import JavaScriptBigActive2 from "../../../icons/services/JavaScriptBigActive2.svg"
import BlockchainBig from "../../../icons/services/BlockchainBig.svg"
import BlockchainBigActive from "../../../icons/services/BlockchainBigActive.svg"
import BlockchainBigActive2 from "../../../icons/services/BlockchainBigActive2.svg"

// Content
const javaScriptArr = [
  {
    id: 0,
    title: `NodeJS`,
    icon: {
      icon: <NodeJsIcon />,
      shape: <ShieldShape />,
      shapeHover: <ShieldShapeHover />,
    },
  },
  {
    id: 1,
    title: `React`,
    icon: {
      icon: <ReactIcon />,
      shape: <ShieldShape />,
      shapeHover: <ShieldShapeHover />,
    },
  },
  {
    id: 2,
    title: `TypeScript`,
    icon: {
      icon: <TypeScriptIcon />,
      shape: <BlockShape />,
      shapeHover: <BlockShapeHover />,
    },
  },
  {
    id: 3,
    title: `VueJS`,
    icon: {
      icon: <VueJSIcon />,
      shape: <SquareShape />,
      shapeHover: <SquareShapeHover />,
    },
  },
]
const blockchainArr = [
  {
    id: 0,
    title: `Tezos`,
    icon: {
      icon: <TezosIcon />,
      shape: <ShieldShape />,
      shapeHover: <ShieldShapeHover />,
    },
  },
  {
    id: 1,
    title: `EOS`,
    icon: {
      icon: <EosIcon />,
      shape: <BlockShape />,
      shapeHover: <BlockShapeHover />,
    },
  },
  {
    id: 2,
    title: `Solidity`,
    icon: {
      icon: <SolidityIcon />,
      shape: <CircleShape />,
      shapeHover: <CircleShapeHover />,
    },
  },
  {
    id: 3,
    title: `Tron`,
    icon: {
      icon: <TronIcon />,
      shape: <CircleShape />,
      shapeHover: <CircleShapeHover />,
    },
  },
  {
    id: 4,
    title: `Ethereum`,
    icon: {
      icon: <EthereumIcon />,
      shape: <CircleShape />,
      shapeHover: <CircleShapeHover />,
    },
  },
  {
    id: 5,
    title: `Ligo`,
    icon: {
      icon: <LigoIcon />,
      shape: <BlockShape />,
      shapeHover: <BlockShapeHover />,
    },
  },
]
const additionalArr = [
  {
    id: 0,
    title: `Sass`,
    icon: {
      icon: <SassIcon />,
      shape: <SquareShape />,
      shapeHover: <SquareShapeHover />,
    },
  },
  {
    id: 1,
    title: `Python`,
    icon: {
      icon: <PythonIcon />,
      shape: <CircleShape />,
      shapeHover: <CircleShapeHover />,
    },
  },
  {
    id: 2,
    title: `Golang`,
    icon: {
      icon: <GoLangIcon />,
      shape: <CircleShape />,
      shapeHover: <CircleShapeHover />,
    },
  },
]

const ExpertiseScreen = () => {
  const [currentActive, setCurrentActive] = useState("js")
  const [currentRight, setCurrentRight] = useState("js")
  const [disabledClick, setDisabledClick] = useState(false)

  const mainCaseToggle = element => {
    const rightCardsAfter = CSSRulePlugin.getRule(
      `.${styles.rightCardStyle}::after`
    )
    const text = document.querySelectorAll(
      `.${styles.rightCardStyle} .${styles.rightText}`
    )
    const content = document.querySelectorAll(
      `.${styles.rightCardStyle} .${styles.rightContent}`
    )
    const shape = document.querySelectorAll(
      `.${styles.rightCardStyle} .${styles.shape}`
    )
    const cardStyle = document.querySelectorAll(`.${styles.rightCardStyle}`)
    const tl = gsap.timeline({ paused: true })
    tl.to(rightCardsAfter, {
      duration: 0.1,
      opacity: 0,
    })
      .to(text, {
        duration: 0.1,
        autoAlpha: 0,
        delay: -0.1,
      })
      .to(content, {
        duration: 0.1,
        transform: `translate(1px, 1px)`,
        delay: -0.1,
      })
      .to(shape, {
        duration: 0.1,
        autoAlpha: 0,
        delay: +0.1,
      })
      .to(content, {
        duration: 0.1,
        transform: `translate(2px, 2px)`,
        delay: -0.1,
      })
      .to(cardStyle, {
        duration: 0.1,
        autoAlpha: 0,
        delay: -0,
        onComplete: toggleRight,
        onCompleteParams: [element],
      })
      .to(cardStyle, {
        duration: 0.1,
        autoAlpha: 1,
        delay: +0.2,
      })
      .to(content, {
        duration: 0.1,
        transform: `translate(1px, 1px)`,
        delay: -0.1,
      })
      .to(shape, {
        duration: 0.1,
        autoAlpha: 1,
        delay: 0,
      })
      .to(rightCardsAfter, {
        duration: 0.1,
        opacity: 1,
        delay: +0.1,
      })
      .to(text, {
        duration: 0.1,
        autoAlpha: 1,
        delay: -0.1,
      })
      .to(content, {
        duration: 0.1,
        transform: `none`,
        delay: -0.1,
      })
    tl.play()
  }

  const toggleRight = element => {
    setCurrentRight(element)
  }

  const toggleMainClick = element => {
    disableClick()
    if (currentActive !== element) {
      setCurrentActive(element)
      mainCaseToggle(element)
    }
  }

  // const [currentActiveMobile, setCurrentActiveMobile] = useState()
  //
  // const toggleMobileClick = element => {
  //   disableClick()
  //   if (currentActiveMobile !== element) {
  //     setCurrentActiveMobile(element)
  //     // mainCaseToggle(element)
  //   } else {
  //     setCurrentActiveMobile('')
  //   }
  // }
  //
  const disableClick = () => {
    setDisabledClick(!disabledClick)
    setTimeout(() => {
      setDisabledClick(false)
    }, 500)
  }

  return (
    <section className={"section " + styles.expertise}>
      <div className="container">
        <div className="row v-center space-between flex-wrap">
          <div className={"heading" + " " + styles.heading}>
            <h2>Expertise</h2>
            <p>Technologies we work with:</p>
          </div>
          <div className={styles.top}>
            <div className={styles.left}>
              <div
                className={
                  styles.leftCard +
                  " " +
                  (currentActive === "js" ? styles.active : "")
                }
              >
                <button
                  className={styles.leftCardInner}
                  onClick={() => toggleMainClick("js")}
                  disabled={disabledClick}
                >
                  <div className={styles.leftCardBorder} />
                  <div className={styles.leftCardContent}>
                    <div className={styles.leftIcon}>
                      <JavaScriptBig />
                      <div className={styles.leftIconActive}>
                        <JavaScriptBigActive />
                      </div>
                    </div>
                    <div className={styles.leftContent}>
                      <h3>JavaScipt</h3>
                      <p>
                        Build performant applications based on Javascript – one
                        language for full-stack development.
                      </p>
                    </div>
                  </div>
                </button>
              </div>
              <div
                className={
                  styles.leftCard +
                  " " +
                  (currentActive === "blockchain" ? styles.active : "")
                }
              >
                <button
                  className={styles.leftCardInner}
                  onClick={() => toggleMainClick("blockchain")}
                  disabled={disabledClick}
                >
                  <div className={styles.leftCardBorder} />
                  <div className={styles.leftCardContent}>
                    <div className={styles.leftIcon}>
                      <BlockchainBig />
                      <div className={styles.leftIconActive}>
                        <BlockchainBigActive />
                      </div>
                    </div>
                    <div className={styles.leftContent}>
                      <h3>Blockсhain</h3>
                      <p>
                        Touch the future of free finance and lack of boundaries
                        with us.
                      </p>
                    </div>
                  </div>
                </button>
              </div>
            </div>
            <div className={styles.right}>
              <div
                className={
                  styles.first +
                  " " +
                  (currentRight === "js" ? styles.active : "")
                }
              >
                <div className={styles.rightMobile}>
                  <div
                    className={
                      styles.leftCard +
                      " " +
                      // (currentActiveMobile === "js" ? styles.active : "")
                      styles.active
                    }
                  >
                    <button
                      className={styles.leftCardInner}
                      // onClick={() => toggleMobileClick("js")}
                      // disabled={disabledClick}
                    >
                      <div className={styles.leftCardBorder} />
                      <div className={styles.leftCardContent}>
                        <div className={styles.leftIcon}>
                          <JavaScriptBig />
                          <div className={styles.leftIconActive}>
                            <JavaScriptBigActive2 />
                          </div>
                        </div>
                        <div className={styles.leftContent}>
                          <h3>JavaScipt</h3>
                          <p>
                            Build performant applications based on Javascript –
                            one language for full-stack development.
                          </p>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
                {/*<div className={styles.rightContainer + ' ' + (currentActiveMobile === 'js' ? styles.active : '')}>*/}
                <div className={styles.rightContainer + ' ' + styles.active}>
                  {javaScriptArr.map(({ id, title, icon }) => (
                    <div className={styles.rightCard} key={id}>
                      <div className={styles.rightCardStyle}>
                        <div className={styles.rightContent}>
                          <div className={styles.icon}>
                            <div className={styles.shape}>
                              {icon.shapeHover}
                            </div>
                            <div className={styles.iconInside}>{icon.icon}</div>
                          </div>
                          <div className={styles.rightText}>
                            <h3>{title}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className={
                  styles.second +
                  " " +
                  (currentRight === "blockchain" ? styles.active : "")
                }
              >
                <div className={styles.rightMobile}>
                  <div
                    className={
                      styles.leftCard +
                      " " +
                      // (currentActiveMobile === "blockchain" ? styles.active : "")
                      styles.active
                    }
                  >
                    <button
                      className={styles.leftCardInner}
                      // onClick={() => toggleMobileClick("blockchain")}
                      // disabled={disabledClick}
                    >
                      <div className={styles.leftCardBorder} />
                      <div className={styles.leftCardContent}>
                        <div className={styles.leftIcon}>
                          <BlockchainBig />
                          <div className={styles.leftIconActive}>
                            <BlockchainBigActive2 />
                          </div>
                        </div>
                        <div className={styles.leftContent}>
                          <h3>Blockсhain</h3>
                          <p>
                            Touch the future of free finance and lack of
                            boundaries with us.
                          </p>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
                {/*<div className={styles.rightContainer  + ' ' + (currentActiveMobile === 'blockchain' ? styles.active : '')}>*/}
                <div className={styles.rightContainer  + ' ' + styles.active}>
                  {blockchainArr.map(({ id, title, icon }) => (
                    <div className={styles.rightCard} key={id}>
                      <div className={styles.rightCardStyle}>
                        <div className={styles.rightContent}>
                          <div className={styles.icon}>
                            <div className={styles.shape}>
                              {icon.shapeHover}
                            </div>
                            <div className={styles.iconInside}>{icon.icon}</div>
                          </div>
                          <div className={styles.rightText}>
                            <h3>{title}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.bottom}>
            {additionalArr.map(({ id, title, icon }) => (
              <div className={styles.bottomCard} key={id}>
                <StyledCard type="reverse">
                  <div className={styles.rightContent}>
                    <div className={styles.icon}>
                      <div className={styles.shape2 + ' ' + (title === 'Sass' && styles.shapeSquare)}>{icon.shapeHover}</div>
                      <div className={styles.iconInside}>{icon.icon}</div>
                    </div>
                    <div className={styles.rightText}>
                      <h3>{title}</h3>
                    </div>
                  </div>
                </StyledCard>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ExpertiseScreen
