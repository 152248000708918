import React, { useState } from "react"

// Components
import StyledCard from "../../Global/StyledCard/StyledCard"

// Styles
import styles from "./faq.module.sass"

// Icons
import ArrowRight from "../../../icons/arrow-right-long.svg"

// Content
const faqArr = [
  {
    question: "Can you give a quote over a quick phone call?",
    answer:
      "Certainly. The pricing in terms of numbers is not difficult. However – as you’d expect, it is not JUST about the price and quote only. The core benchmark of our pricing depends on a lot of factors such as the composition of the team, experience level required, and the type of engagement as well as the length of the minimum commitment to the contract.",
  },
  {
    question:
      "Whom do I speak in case I need some inputs, ideas, or consultation?",
    answer:
      "We love talking about industry, insights, sharing our knowledge, experience, and case studies with our clients. Just get in touch with one of our Developers, our Project Manager, or our BDM - we are really just a call away.",
  },
  {
    question: "What about the secrecy of my Idea?",
    answer:
      "Everything begins with an Idea and we know – each idea is precious. We take great responsibility in ensuring your idea remains confidential. To accompany our commitment, we sign the Non-Disclosure Agreement (NDA) right before any discussion. We also have legal agreements with all of our employees including software development teams for maintaining security and trust.",
  },
  {
    question: "Do you have a Service Level Agreement in place?",
    answer:
      "Yes, we do have it. We are a trusted software development company since 2018, adhering to the laws of the IT industry. We have a service level agreement in place as a symbol of trust and quality of IT service delivery.",
  },
  {
    question: "Do all of your developers speak English?",
    answer:
      "Yes, all of our developers are good at either writing or speaking English and can efficiently communicate. In case we have a very skilled developer who’s lacking communication skills - we will assign a Project Manager to mediate the communication process and ensure there’s no communication loss and strategic focus.",
  },
  {
    question:
      "I have a technical team at my end. Can you work in conjunction with them?",
    answer:
      "Of course. We’d love to collaborate with fellow team members at your end who can make a great team along with our team. We recommend hiring a team of developers and managers from our us – who can fill in the gap of your team to establish a perfect balance for your project.",
  },
  {
    question: "Can I contact your developers directly over the phone?",
    answer:
      "Absolutely. We do not hide our team. Today in the era of communication, we believe that transparency is a key solution to many problems. We will share with you all the contact info of our developers – their email address and Skype.",
  },
]

const FaqScreen = () => {
  const [current, setCurrent] = useState(0)
  const handleClick = index => {
    setCurrent(index)
  }

  return (
    <section className={"section "}>
      <div className="container">
        <div className="row v-center space-between flex-wrap">
          <div className={"heading"}>
            <h2>FAQ</h2>
          </div>
          <div className={styles.wrapper}>
            <div className={styles.questions}>
              {faqArr.map(({ question, answer }, index) => (
                <div
                  className={
                    styles.question +
                    " " +
                    (index === current ? styles.active : "")
                  }
                  onClick={() => handleClick(index)}
                >
                  <div className={styles.content}>
                    <div className={styles.inside}>
                      <div className={styles.inner}>
                        <div className={styles.number}>
                          {index + 1 < 10 ? `0${index + 1}` : index + 1}
                        </div>
                        <h3>{question}</h3>
                      </div>
                      <div className={styles.arrow}>
                        <ArrowRight />
                      </div>
                    </div>
                    <div className={styles.questionAnswerWrapper + ' ' + (current === index ? styles.active : null)}>
                      <div className={styles.questionAnswer}>{answer}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.answers}>{faqArr[current].answer}</div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FaqScreen
