import React from "react"

// Components
import StyledCard from "../../Global/StyledCard/StyledCard"

// Styles
import styles from "./focus-on.module.sass"

// Icons
import EnterpriseIcon from "./../../../icons/servicesPage/focus-on/enterprise.svg"
import MediumBusinessesIcon from "./../../../icons/servicesPage/focus-on/medium-businesses.svg"
import StartupsIcon from "./../../../icons/servicesPage/focus-on/startups.svg"

// Content
const focusOnArr = [
  {
    id: 0,
    title: `Enterprise`,
    description: `Are you representing a corporation that has plans to develop or change its direction to the cryptocurrency/blockchain market? Do you want to start an R/D? We are ready to be your contractor!`,
    icon: <EnterpriseIcon />,
  },
  {
    id: 1,
    title: `Medium Businesses`,
    description: `Are you a company in need of technical and financial analysis for minimizing risks and making profitable products? Do you run a DeFi or Dapp? We are ready to guide you to your success.`,
    icon: <MediumBusinessesIcon />,
  },
  {
    id: 2,
    title: `Startups with money`,
    description: `Whether you have just started thinking about your blockchain-related project, want to develop a POC / MVP, or make tech research, we can step in to help at any stage of the process.`,
    icon: <StartupsIcon />,
  },
]

const FocusOnScreen = () => {
  return (
    <section className={"section"}>
      <div className="container">
        <div className="row v-center space-between flex-wrap">
          <div className={"heading" + " " + styles.heading}>
            <h2>We Focus on</h2>
            <p>Scale your development, design and build great solutions!</p>
          </div>
          <div className={styles.wrapper}>
            <div className={styles.card}>
              <StyledCard type="reverse">
                <div className={styles.content}>
                  <div className={styles.icon}>{focusOnArr[0].icon}</div>
                  <h3>{focusOnArr[0].title}</h3>
                  <p>{focusOnArr[0].description}</p>
                </div>
              </StyledCard>
            </div>
            <div className={styles.card}>
              <div className={styles.medBusiness}>
                <div className={styles.content}>
                  <div className={styles.icon}>{focusOnArr[1].icon}</div>
                  <h3>{focusOnArr[1].title}</h3>
                  <p>{focusOnArr[1].description}</p>
                </div>
              </div>
            </div>
            <div className={styles.card}>
              <StyledCard type="reverse">
                <div className={styles.content}>
                  <div className={styles.icon}>{focusOnArr[2].icon}</div>
                  <h3>{focusOnArr[2].title}</h3>
                  <p>{focusOnArr[2].description}</p>
                </div>
              </StyledCard>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FocusOnScreen
