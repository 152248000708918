import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ServicesScreen from "../components/Home/01-Services/services"
import ExpertiseScreen from "../components/Services/01-Expertise/expertise"
import IndustriesScreen from "../components/Services/02-Industries/industries"
import FocusOnScreen from "../components/Services/03-FocusOn/forusOn"
import CollaborationScreen from "../components/Services/04-Collaboration/collaboration"
import AwardsScreen from "../components/Home/06-Awards/awards"
import GFClutchSection from "../components/Services/05-GFClutch/gfClutch"
import ContactsScreen from "../components/Home/07-Contacts/contacts"
import FaqScreen from "../components/Services/06-Faq/faq"

const ServicesPage = () => (
  <Layout>
    <SEO
      title="Madfish | Software development company experienced in Blockchain and JS web"
      description="We offer full-cycle development (discovery phase and business analysis, software architecture and development, project management, QA, support and launching)"
    />
    <ServicesScreen />
    <ExpertiseScreen />
    <IndustriesScreen />
    <FocusOnScreen />
    <CollaborationScreen />
    <AwardsScreen />
    <GFClutchSection />
    <ContactsScreen />
    <FaqScreen />
  </Layout>
)

export default ServicesPage
